import axiosInterceptors from "../lib/axiosInterceptors";
import { getEnvironment } from "../config/config";

/**
 * Get commercial rules
 */
export async function getCommercialRules(type: "area" | "playlist" | "client", clientIdOrAreaIdOrPlaylistId: number) {
  return await axiosInterceptors.get(
    `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/rule/${type}/${clientIdOrAreaIdOrPlaylistId}`,
    {}
  );
}

export async function getSynchedScheduleDate(type: string, clientId: number) {
  if (type === "client")
    return await axiosInterceptors.get(
      `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/syb-synch/${clientId}`,
      {}
    );
  return { data: { lastSynched: "" } };
}

export async function synchSchedulesWithSyb(clientId: number) {
  return await axiosInterceptors.post(
    `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/syb-synch/${clientId}`,
    {}
  );
}
/**
 * Add or Update commercial rules
 */
export async function postCommercialRule(
  type: "area" | "playlist" | "client",
  clientIdOrAreaIdOrPlaylistId: number,
  rule: any
) {
  return await axiosInterceptors.post(
    `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/rule/${type}/${clientIdOrAreaIdOrPlaylistId}`,
    rule
  );
}

export async function applySybRule(clientId: number) {
  return await axiosInterceptors.post(
    `${getEnvironment()?.HOST_ROCK_API}/clients/commercial/syb-apply/${clientId}`,
    {}
  );
}
