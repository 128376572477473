import { useEffect, useState } from "react";
import { FieldArray, Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { BsQuestionCircle, BsXCircle, BsSearch } from "react-icons/bs";
import classNames from "classnames";
import moment from "moment";
import Select from "react-select";

// style components
import { Input } from "../../../../components/fields/input";
import { Select as CustomSelect } from "../../../../components/fields/select";
import {
  assignDeviceForm,
  commercialInjectionRulesForm,
  renameAreaForm,
  changeAudioSettingForm,
  renameUserForm,
} from "../../../../components/formValidation/formValidation";
import { GlobalModal } from "../../../../components/modal";
import {
  hideActionsSuccess,
  showErrorMessageSuccess,
  isLoadingSuccess,
} from "../../../../redux-toolkit/reducers/actionsSlice";
import { addArea, changeDevice, updateAreaRecord } from "../../../../services/locationsServices";
import styles from "./location-actions.module.scss";
import { RootState } from "../../../../redux-toolkit/store";
import { GlobalErrorMessage } from "../../../users/components/errorMessage";
import { LoadingData } from "../../../../components/loading";
import { ACTIONS_ERROR_MESSAGE } from "../../../../constants/globalText";
import { GlobalButton } from "../../../../components/button";
import { Tooltip } from "../../../../components/tooltip";
import { SearchWithOptions } from "../../../../components/fields/search";
import { Checkbox } from "../../../../components/fields/checkbox";
import { DateRangePicker } from "../../../../components/fields/datePicker";
import { getCoreChannelsListForTackInjectionModal } from "../../../../services/core-ChannelsServices";
import {
  getCommercialRules,
  getSynchedScheduleDate,
  synchSchedulesWithSyb,
  postCommercialRule,
  applySybRule,
} from "../../../../services/commercialRulesServices";
import { RowCard } from "../../../../components/cards";
import { Panel } from "../../../../components/panel";
import panelStyles from "../../../../components/panel/panel.module.scss";

interface LocationActionsProps {
  size?: "sm" | "lg" | "xl";
  footer?: any;
  title?: string;
  show: boolean;
  area?: { name: string; id: number; streaming?: any; audioSetting?: string };
  handleClose: any;
  type?: "area" | "playlist" | "client";
  clientIdOrAreaIdOrPlaylistId?: number;
  nameDisplay?: string;
  children?: any;
  devices?: any;
}

type State = { coreChannels: any[]; rules: any[]; synchDate: string };

/**
 * View area commercial injection rules
 */

export const ViewCommercialInjectionRules = ({
  footer,
  show,
  handleClose,
  type = "client",
  clientIdOrAreaIdOrPlaylistId = 0,
  nameDisplay,
}: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const initialState: State = {
    coreChannels: [],
    rules: [],
    synchDate: "",
  };
  const [state, setState] = useState<State>(initialState);
  const [isSynching, setSynching] = useState<boolean>(false);
  const [isApplyingRule, setApplyingRule] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let isSubscribed = true;

    const getAllData = async () => {
      dispatch(isLoadingSuccess(true));
      await Promise.all([
        getCoreChannelsListForTackInjectionModal(),
        getCommercialRules(type, clientIdOrAreaIdOrPlaylistId),
        getSynchedScheduleDate(type, clientIdOrAreaIdOrPlaylistId),
      ])
        .then((response) => {
          if (isSubscribed) {
            setState({
              coreChannels: response[0].data,
              rules: response[1].data.rule?.length ? response[1].data.rule : [],
              synchDate: response[2].data.dateSynched,
            });
            dispatch(isLoadingSuccess(false));
          }
        })
        .catch((error) => {
          dispatch(isLoadingSuccess(false));
          throw new Error(error);
        });
    };

    getAllData();

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const commercialInjectionRulesData = async (values: any) => {
    dispatch(isLoadingSuccess(true));

    await postCommercialRule(type, clientIdOrAreaIdOrPlaylistId, values.rules)
      .then((_) => {
        dispatch(isLoadingSuccess(false));
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  const synchActiveScheduleWithSyb = async (clientId: number) => {
    setSynching(true);
    try {
      const response = await synchSchedulesWithSyb(clientId);
      setState({ ...state, synchDate: response.data });
    } catch (e: any) {
      setState({ ...state, synchDate: e.response.data.message });
    } finally {
      setSynching(false);
    }
  };

  const applyInjectionToSyb = async (clientId: number) => {
    setApplyingRule(true);
    try {
      await applySybRule(clientId);
    } catch (e: any) {
      console.error(e);
    } finally {
      setApplyingRule(false);
    }
  };

  const selectBoxesStyle = {
    height: "35px",
    borderRadius: "3px",
    borderColor: "lightGrey",
    margin: "0 7px 0 0",
  };

  const blankRule = {
    core_channel_id: "",
    repetition: 5,
    repetition_unit: "tracks",
    order: 1,
    pick_type: "random",
    startDate: null,
    endDate: null,
    injection100: false,
  };

  const initialValues: { synchDate: string; rules: any[] } = {
    synchDate: state.synchDate,
    rules: state.rules.map((details: any) => ({
      ...details,
    })),
  };

  return (
    <GlobalModal
      title={`${type && type.charAt(0).toUpperCase() + type.slice(1)} Commercial Rules Injection`}
      show={show}
      handleClose={handleClose}
      size="xl"
      footer={footer}
    >
      <div className={styles.modal}>
        <Formik
          initialValues={initialValues}
          onSubmit={commercialInjectionRulesData}
          enableReinitialize
          validationSchema={commercialInjectionRulesForm}
        >
          {({ handleChange, handleBlur, setFieldValue, values, errors }) => {
            return (
              <Form>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <FieldArray
                  name="rules"
                  render={(arrayHelpers) => (
                    <div>
                      <div className={classNames(styles.flexRowWrapModalHeader)}>
                        <p>{nameDisplay}</p>
                        {type === "client" && (
                          <p>
                            <b>Last SYB synch:</b> {state.synchDate ? state.synchDate : "Never"}
                          </p>
                        )}
                      </div>
                      {values.rules.length > 0 &&
                        values.rules.map((rule, index: number) => {
                          if (rule.startDate && rule.startDate.toString().length < 13) {
                            rule.startDate = rule.startDate * 1000;
                            rule.endDate = rule.endDate * 1000;
                          }

                          const defaultSelectedCoreChannel = (index: number) => {
                            const selectedCC = values.rules[index]?.core_channel_id;
                            if (selectedCC) {
                              return state.coreChannels.filter((channel: any) => channel.id === selectedCC);
                            }
                          };

                          return (
                            <div key={index}>
                              <RowCard>
                                <div className="row g-3 align-items-center">
                                  <div className="col-auto">
                                    <BsXCircle
                                      size={20}
                                      className="text-danger"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => arrayHelpers.remove(index)} // remove injection from the list
                                    />
                                  </div>
                                  <div className="col-3">
                                    <SearchWithOptions
                                      placeholder="Select a core channel..."
                                      id={`rules.${index}.core_channel_id`}
                                      // options={values.coreChannels}
                                      options={state.coreChannels}
                                      labelKey="name"
                                      leftIcon={<BsSearch />}
                                      clearButton
                                      maxResults={30}
                                      selected={defaultSelectedCoreChannel(index)}
                                      onChange={(selected: any) => {
                                        setFieldValue(
                                          `rules.${index}.core_channel_id`,
                                          selected && selected[0] && selected[0].id
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-auto">
                                    <label>Every</label>
                                  </div>
                                  <div className="col-1">
                                    <Input
                                      min={1}
                                      id={`rules.${index}.repetition`}
                                      name={`rules.${index}.repetition`}
                                      type="number"
                                      placeholder="Value"
                                      onChange={handleChange(`rules.${index}.repetition`)}
                                      onBlur={handleBlur(`rules.${index}.repetition`)}
                                      autoCapitalize="none"
                                      error={errors.rules}
                                      size="form-control-sm"
                                      onChangeCapture={() =>
                                        actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))
                                      }
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <CustomSelect
                                      style={selectBoxesStyle}
                                      name={`rules.${index}.repetition_unit`}
                                      id={`rules.${index}.repetition_unit`}
                                      options={[
                                        {
                                          label: "Tracks",
                                          value: "tracks",
                                        },
                                        {
                                          label: "Minutes",
                                          value: "minutes",
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <CustomSelect
                                      style={selectBoxesStyle}
                                      name={`rules.${index}.pick_type`}
                                      id={`rules.${index}.pick_type`}
                                      options={[
                                        {
                                          label: "No Repeats",
                                          value: "random-exclusive",
                                        },
                                        {
                                          label: "Allow Repeats",
                                          value: "random",
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div className="col-auto" style={{ marginTop: "33px" }}>
                                    <DateRangePicker
                                      id={`rules.${index}.startDate`}
                                      error={
                                        <ErrorMessage
                                          component="span"
                                          className="field_error"
                                          name={`rules.${index}.startDate`}
                                        />
                                      }
                                      defaultPickerValue={[
                                        rule.startDate ? moment(rule.startDate).startOf("day") : null,
                                        rule.endDate ? moment(rule.endDate).startOf("day") : null,
                                      ]}
                                      onChange={(values: any) => {
                                        setFieldValue(
                                          `rules.${index}.startDate`,
                                          (values && values[0] && new Date(values[0]).getTime()) || null
                                        );
                                        setFieldValue(
                                          `rules.${index}.endDate`,
                                          (values && values[1] && new Date(values[1]).getTime()) || null
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <Checkbox
                                      type="checkbox"
                                      name={`rules.${index}.injection100`}
                                      id={`rules.${index}.injection100`}
                                      value="100% Injection"
                                    />
                                  </div>
                                </div>
                              </RowCard>
                            </div>
                          );
                        })}
                      <div className={classNames(styles.flexRowWrapModalFooter)}>
                        <div className={styles.buttonsRow}>
                          <GlobalButton
                            format="primary"
                            size="sm"
                            disabled={actions.actionsIsLoading ? true : false}
                            onClick={() => arrayHelpers.push(blankRule)} // insert an empty string at a position
                          >
                            Add Rule
                          </GlobalButton>
                          {type === "client" && (
                            <>
                              <GlobalButton
                                format="secondary"
                                size="sm"
                                disabled={isSynching}
                                onClick={() => synchActiveScheduleWithSyb(clientIdOrAreaIdOrPlaylistId)} // insert an empty string at a position
                              >
                                Syb Synch
                              </GlobalButton>
                              <GlobalButton
                                format="secondary"
                                size="sm"
                                disabled={isSynching || !state.synchDate || isApplyingRule}
                                onClick={() => applyInjectionToSyb(clientIdOrAreaIdOrPlaylistId)} // insert an empty string at a position
                              >
                                Apply Syb rules
                              </GlobalButton>
                            </>
                          )}
                        </div>

                        {footer && <div>{footer}</div>}
                      </div>
                    </div>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * resend schedule
 */
export const ResendSchedule = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  return (
    <GlobalModal title="Resend Schedule" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to resend the schedule?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * update software
 */
export const UpdateSoftware = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  return (
    <GlobalModal title="Update software version?" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to update? This will cause disruption to the music for a few minutes.</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Assign device to an area
 */
export const AssignDevice = ({ footer, area, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  const assignDeviceData = async (values: any) => {
    const changeDeviceRecord = {
      areaId: actions.area.id,
      deviceId: values.deviceId.toLowerCase(),
      deviceType: values.deviceType,
      businessId: actions.location.id,
    };

    await changeDevice(changeDeviceRecord);
  };
  const selectBoxesStyle = {
    height: "35px",
    borderRadius: "3px",
    borderColor: "lightGrey",
    margin: "0 7px 0 0",
  };

  const HelpMessage = `
    The device serial should be a letter followed by a 5 digit number, e.g. c-01234. 
    For a fake device the same applies but has to start with letter f, e.g. f-01234`;

  return (
    <GlobalModal
      title={`Assign Device for ${area?.name} [${area?.id}]`}
      show={show}
      handleClose={handleClose}
      footer={footer}
    >
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ deviceId: actions.generateWebPlayer.thingName, deviceType: "" }}
          validationSchema={assignDeviceForm}
          onSubmit={assignDeviceData}
          enableReinitialize
        >
          {({ handleChange, handleBlur, values, errors }) => (
            <Form>
              <div>
                {actions.generateWebPlayer.deviceCode && (
                  <p>Web Player device code: {actions.generateWebPlayer.deviceCode}</p>
                )}
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div className={styles.flexRowWrap}>
                  <div className={styles.serialNumber}>
                    <Input
                      id="deviceId"
                      name="deviceId"
                      type="text"
                      placeholder="Device Serial"
                      onChange={handleChange("deviceId")}
                      value={values.deviceId}
                      onBlur={handleBlur("deviceId")}
                      autoCapitalize="none"
                      error={errors.deviceId}
                      size="form-control-sm"
                      onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>
                  <div className={styles.tooltipIconLeftSpace}>
                    <span className="position-relative">
                      <span className={styles.tooltipIcon}>
                        <BsQuestionCircle
                          className="lead bi-question-circle ms-1"
                          style={{ fontSize: "1rem", position: "relative", top: "-1px" }}
                        />
                      </span>
                      <span className={styles.tooltipIconHide}>
                        <Tooltip message={HelpMessage} />
                      </span>
                    </span>
                  </div>

                  <div className={styles.deviceOption}>
                    <CustomSelect
                      style={selectBoxesStyle}
                      name="deviceType"
                      id="deviceType"
                      options={[
                        {
                          label: "ambie-player",
                          value: "zoetrope-device",
                        },
                        {
                          label: "fake-device",
                          value: "fake-device",
                        },
                      ]}
                    />
                  </div>
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Assign device to an area with action require
 */
export const AssignDeviceActionRequired = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Action Require" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>{actions.actionRequiredInfo.message}</p>
        <p>Would you like to continue the process?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Rename area name
 */
export const RenameArea = ({ footer, show, area, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  const renameAreaNameData = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateAreaRecord(actions.area.id, { name: values.areaName })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  return (
    <GlobalModal title="Rename Area" show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ areaName: area && area.name }}
          validationSchema={renameAreaForm}
          onSubmit={renameAreaNameData}
        >
          {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => (
            <Form>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <Input
                    id="areaName"
                    name="areaName"
                    type="text"
                    placeholder="Area name"
                    onChange={handleChange("areaName")}
                    value={values.areaName}
                    onBlur={handleBlur("areaName")}
                    autoCapitalize="none"
                    error={errors.areaName}
                    size="form-control-sm"
                    onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                  />
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

export const ChangeAreaAudioSetting = ({ footer, show, area, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  const audioOptions = [
    { label: "Standard Mono", value: "standard_mono" },
    { label: "HIFI Mono", value: "hifi_mono" },
    { label: "Standard Stereo - not recommended", value: "standard_stereo" },
    { label: "HIFI Stereo - not recommended", value: "hifi_stereo" },
  ];

  const warningOptions = {
    standard_stereo: "standard stereo",
    hifi_stereo: "hifi stereo",
  };

  const changeAudioSetting = async (values: any) => {
    dispatch(isLoadingSuccess(true));
    await updateAreaRecord(actions.area.id, { audio_setting: values.audioSetting })
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
        throw new Error(error);
      });
  };

  return (
    <GlobalModal title="Change Audio Setting" show={show} handleClose={handleClose}>
      <div className={styles.modal}>
        <Formik
          form
          initialValues={{ audioSetting: area && area.audioSetting }}
          validationSchema={changeAudioSettingForm}
          onSubmit={changeAudioSetting}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <Select
                    id="audioSetting"
                    className="flex-grow-4 time-select channel-list"
                    options={audioOptions}
                    value={audioOptions.find((c: any) => c.value === values.audioSetting)}
                    onChange={(selected) => selected && selected.value && setFieldValue("audioSetting", selected.value)}
                  />
                  {(values.audioSetting === "hifi_stereo" || values.audioSetting === "standard_stereo") && (
                    <p
                      style={{
                        marginLeft: "5px",
                        marginTop: "5px",
                        color: "#ed4c78",
                        width: "95%",
                      }}
                    >
                      {`The ${
                        warningOptions[values.audioSetting]
                      } setting is going to cause the music to play a mix of stereo and mono tracks if not all tracks in the
                          playlists are in stereo format`}
                    </p>
                  )}
                </div>

                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </GlobalModal>
  );
};

/**
 * Update steaming status
 */
export const StreamingStatus = ({ footer, show, area, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  return (
    <GlobalModal title="Main" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>{area && area.streaming && area.streaming.enabled ? `Disable streaming` : `Enable streaming`}?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Deactivate location area
 */
export const DeactivateArea = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Deactivate Area" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to deactivate the area?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Activate location area
 */
export const ActivateArea = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal title="Activate Area" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to activate the area?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Rename business name
 */
export const RemoveDevice = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  return (
    <GlobalModal title="Remove device from area" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>Are you sure you want to remove the device from this area?</p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * View Schedule
 */
export const ViewSchedule = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  return (
    <GlobalModal title="View Schedule" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to view {actions.area && actions.area.schedule && actions.area.schedule.name} schedule
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Change Schedule
 */
export const ChangeSchedule = ({ footer, show, handleClose }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  return (
    <GlobalModal title="Change Schedule" show={show} handleClose={handleClose} footer={footer}>
      <div className={styles.modal}>
        <p>
          Are you sure you want to change the schedule of <strong>{actions.area && actions.area.name}</strong> to{" "}
          <strong>{actions.area && actions.area.schedule && actions.area.schedule.name}</strong>
        </p>
        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}

        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </GlobalModal>
  );
};

/**
 * Add new area
 */
export const AddArea = ({ show }: { show: boolean }) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);
  const dispatch = useDispatch();

  const addAreaData = async (values: any) => {
    const data = {
      name: values.name,
      business_id: actions.location.id,
    };

    dispatch(isLoadingSuccess(true));
    await addArea(data)
      .then((response) => {
        dispatch(hideActionsSuccess());
      })
      .catch((error) => {
        dispatch(isLoadingSuccess(false));
        dispatch(showErrorMessageSuccess(ACTIONS_ERROR_MESSAGE));
      });
  };

  const initialValues = {
    name: "",
  };

  return (
    <Formik
      form
      initialValues={initialValues}
      validationSchema={renameUserForm}
      onSubmit={addAreaData}
      enableReinitialize
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors }) => (
        <Form>
          <Panel
            show={show}
            handleClose={() => {
              dispatch(hideActionsSuccess());
            }}
            title="Add New Area"
            width="50%"
            maxWidth="500px"
            footer={
              <div className={panelStyles.footer}>
                <div className={panelStyles.footer_left}>
                  <GlobalButton
                    format="white"
                    size="sm"
                    onClick={() => {
                      dispatch(hideActionsSuccess());
                    }}
                  >
                    Close
                  </GlobalButton>
                </div>
                <div>
                  <GlobalButton type="submit" format="primary" size="sm">
                    Add Area
                  </GlobalButton>
                </div>
              </div>
            }
          >
            <div>
              <div>
                <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
                {actions.actionsIsLoading && <LoadingData />}
                <div>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      autoCapitalize="none"
                      error={errors.name}
                      size="form-control-sm"
                      onChangeCapture={() => actions.actionsErrorMessage && dispatch(showErrorMessageSuccess(""))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Form>
      )}
    </Formik>
  );
};

/**
 * Unsave Device Status
 */
export const UnsaveDeviceStatus = ({ show, handleClose, devices }: LocationActionsProps) => {
  const actions = useSelector((state: RootState) => state.actionsSlice);

  return (
    <GlobalModal
      title={`${devices.length} ${devices.length > 1 ? `players` : `player`}  failed to update, please contact IT team`}
      show={show}
      handleClose={handleClose}
      className={styles.errorModal}
    >
      <div className={styles.modal}>
        <div>
          <ul>
            {devices.map((device: string, index: number) => (
              <li key={index}>{device} </li>
            ))}
          </ul>
        </div>

        <div>{actions.actionsErrorMessage && <GlobalErrorMessage message={actions.actionsErrorMessage} />}</div>
        {actions.actionsIsLoading && <LoadingData />}
      </div>
    </GlobalModal>
  );
};
